import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { RichText } from 'prismic-reactjs';
import htmlSerializer from '../../utils/htmlSerializer';

import DocumentCard from '../Common/DocumentCard';
import DocumentCardsContainer from '../Common/DocumentCardsContainer';
import PortalVideoNew from '../Common/PortalVideoNew'
import PortalVideoUpdateNew from '../Common/PortalVideoUpdateNew';

const FormationProcessTrainingSectionNewUpdate = () => {
  const data = useStaticQuery(graphql`
    query FormationProcessTrainingSectionNewUpdateQuery {
      prismicNewFormationInformation {
        data {
          section_title
          section_title2
          section_description {
            richText
          }
          first_button_text
          first_button_link {
            url
          }
          second_button_description {
            richText
          }
          second_button_text
          second_button_link {
            url
          }
          pdf_files_list {
            pdf_file_title
            pdf_download_link {
              url
            }
            coming_soon
          }
          vimeo_videos {
            video_description {
              richText
            }
            video_title
            video_type
            wistia_video_id
            vimeo_video_id
          }
        }
      }
    }
  `)

  const doc = data.prismicNewFormationInformation
  const videos = doc.data.vimeo_videos

  return (
    <>
      <div className="w-full max-w-screen-lg mt-16">
        <h2 className="text-2xl md:text-3xl 2xl:text-4xl text-gray-900 font-bold">
          {doc.data.section_title}
        </h2>

        {doc.data.section_description.richText
          ?
          <div className="mt-6">
            <RichText
              render={doc.data.section_description.richText}
              htmlSerializer={htmlSerializer}
            />
          </div>
          :
          null
        }

        <div className='w-full md:w-1/2 flex items-center justify-center md:justify-start mt-8'>
          <a
            href={doc.data.first_button_link.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="w-full flex items-center px-4 py-2 md:px-5 md:py-3 border border-transparent font-bold rounded-md text-2xl text-white tracking-widest bg-red-800 hover:bg-red-900 mx-auto">
              {doc.data.first_button_text}
            </button>
          </a>
        </div>

        {doc.data.second_button_description.richText
          ?
          <div className="mt-8">
            <RichText
              render={doc.data.second_button_description.richText}
              htmlSerializer={htmlSerializer}
            />
          </div>
          :
          null
        }

        <div className='w-full md:w-1/2 flex items-center justify-center md:justify-start mt-8'>
          <a
            href={doc.data.second_button_link.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="w-full flex items-center px-4 py-2 md:px-5 md:py-3 border border-transparent font-bold rounded-md text-2xl text-white tracking-widest bg-red-800 hover:bg-red-900 mx-auto">
              {doc.data.second_button_text}
            </button>
          </a>
        </div>
      </div>

      <DocumentCardsContainer containerTitle={doc.data.section_title2}>
        {doc.data.pdf_files_list.map(item => {
          return (
            item.coming_soon
            ?
            <DocumentCard
              cardTitle={item.pdf_file_title}
              internalLink
              buttonComingSoon
            />
            :
            <DocumentCard
              cardTitle={item.pdf_file_title}
              buttonLink={item.pdf_download_link.url}
            />
          )
        })}
      </DocumentCardsContainer>

      <div className='w-full grid grid-cols-1 xl:grid-cols-2 gap-6 xl:gap-16 mt-8 xl:mt-16 mb-6'>
        {videos.map(item => {
          return (
            item.video_type === 'Vimeo'
            ?
            <PortalVideoNew
              videoID={item.vimeo_video_id}
              videoTitle={item.video_title}
            >
              <RichText
                render={item.video_description.richText}
                htmlSerializer={htmlSerializer}
              />
            </PortalVideoNew>
            :
            <PortalVideoUpdateNew key={item.wistia_video_id} videoID={item.wistia_video_id} videoTitle={item.video_title}>
              <RichText render={item.video_description.richText} htmlSerializer={htmlSerializer} />
            </PortalVideoUpdateNew>
          )
        })}
      </div>
    </>
  )
}

export default FormationProcessTrainingSectionNewUpdate