import React from 'react';
import { compose } from 'recompose';

import DashboardWrapper from '../components/Dashboard/DashboardWrapper';
import Layout from '../components/layout';
import {
  withAuthorization,
} from '../components/Session';
import HeadData from "../data/HeadData"
import BackButton from '../components/Common/BackButton';
import FormationProcessTrainingSectionNewUpdate from '../components/Sections/FormationProcessTrainingSectionNewUpdate';

const FormationProcessTrainingPageBase = () => (
  <DashboardWrapper pageName='New Formation Information'>
    <div className="relative pt-4 pb-32 md:pt-12 2xl:pt-20 md:px-8 px-0">
      <div className="container mx-auto px-4">
        <FormationProcessTrainingSectionNewUpdate />

        <BackButton buttonLink='/dashboard' />
      </div>
    </div>
  </DashboardWrapper>
);

const condition = authUser => !!authUser;

const FormationProcessTrainingPage = compose(
  withAuthorization(condition),
)(FormationProcessTrainingPageBase);

const FormationProcessTraining = () => (
  <Layout>
    <HeadData title='New Formation Information' />
    <FormationProcessTrainingPage />
  </Layout>
)

export default FormationProcessTraining
